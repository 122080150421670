import React from 'react';
import { Button, Layout } from 'components';

import './thankyou.scss';

const Thankyou = () => (
  <Layout>
    <section className="thankyou-page">
      <div className="wrapper">
        <h1 className="thankyou-page-title">
          Thank you for registering your interest. Your application has been received.
        </h1>
        <Button to="/">Back to home</Button>
      </div>
    </section>
  </Layout>
);

export default Thankyou;
